import * as fbq from '../../lib/fbpixel';
import axios from 'axios';
import { Container } from '../common/AltCard';
import { Link } from 'react-router-dom';

const Empower = () => {
    const stagingEnpoint = 'https://stagingapi.pakam.ng/api/facebook/notify'

  
    const handleDownloadAndroid= async() => {
      fbq.event('Download', { device: 'Andriod' })
      
      try {
        const res = await axios.post(stagingEnpoint, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
      } catch (error) {
        console.log(error)
      }
    }
  
    const handleDownloadIOS= async() => {
      fbq.event('Download', { device: 'IOS' })
      
      try {
        const res = await axios.post(stagingEnpoint, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
      } catch (error) {
        console.log(error)
      }
    }

  return (
    <Container>
        <div className='shadowM w-full bg-[#F8FAE3] rounded-lg md:rounded-[32px] my-10 py-[32px] px-4 md:px-[60px] lg:px-[80px] flex justify-between items-center '>
            <div>
                <h1 className=' font-[800] text-[24px] md:text-[44px] lg:text-[46px] text-[#35393F] tracking-[-4%] leading-[36px]  md:leading-[50px] font-rob'>Empower Your Wallet, Your <br />Health & <span className='italic font-playfair text-[32px] md:text-[52px] lg:text-[52px] font-[800]'>The Environment</span></h1>
                {/* <p className='text-[#444951] font-[600] text-[20px] md:text-[36px] text-center font-Rob '>Earn and save the environment</p> */}
                <p className=' text-[#0F172A] text-[14px] font-[400] lg:w-[55%] py-6 font-Rob'>Whether you're an individual, a corporate business, school, or an estate, Pakam makes waste recycling simple, rewarding, and impactful.</p>
                <div className='flex space-x-10'>
                        <a  href="https://play.google.com/store/apps/details?id=com.pakamcustomer">
                        <button onClick={handleDownloadAndroid}>
                            <img className='md:h-[50px] md:w-[180px] h-[40px] w-[130px]' src="/img/get-it-on-google-play-badge.png" alt="" />
                        </button></a>
                        <a  href="https://apps.apple.com/ng/app/pakam-household-recycling-app/id1539296957">
                        <button onClick={handleDownloadIOS}>
                            <img className='md:h-[50px] md:w-[180px] h-[40px] w-[130px]' src="/img/app store badge.png" alt="" />
                        </button></a>

                    </div>            
            </div>
            <div className='hidden lg:block'>
                <img src="/img/whyPk.png" className='h-[300px]' alt="" />
            </div>

        </div>     
    </Container>
  )
}

export default Empower


export const Expand =()=>{
  return (
    <Container>
        <div className='shadowM w-full bg-[#FBE2B7] rounded-lg md:rounded-[32px] my-10 py-[32px] px-4 md:px-[60px] lg:px-[80px] flex justify-between items-center '>
            <div>
                <h1 className=' font-[800] text-[24px] md:text-[44px] lg:text-[46px] text-[#35393F] tracking-[-4%] leading-[36px]  md:leading-[50px] font-rob'>Expand & Grow <span className='italic font-playfair text-[32px] md:text-[52px] lg:text-[52px] font-[800]'>Your Business</span></h1>
                {/* <p className='text-[#444951] font-[600] text-[20px] md:text-[36px] text-center font-Rob '>Earn and save the environment</p> */}
                <p className=' text-[#0F172A] text-[14px] font-[400] lg:w-[55%] py-6 font-Rob'>Join Africa’s fastest-growing waste banking platform and connect with more waste generators while increasing efficiency and revenue</p>
                <div className='flex gap-6 flex-col md:flex-row'>
                    <Link to='https://recycletogether.pakam.ng/' className='text-white text-center hover:font-[600] font-[400] px-8 py-2 mr-2 rounded-[3rem] bg-primary border-1 border-primary'>Request Demo</Link>
                    <Link to='/onboarding' className='text-primary hover:font-[600] text-center font-[400] px-8 py-2 mr-2 rounded-[3rem] bg-transparent border-2 border-primary'>Create Account</Link>

                    </div>            
            </div>
            <div className='hidden lg:block'>
                <img src="/img/collectorImage.png" className='h-[300px]' alt="" />
            </div>

        </div>     
    </Container>
  )}
  
export const Build =()=>{
  return (
    <Container>
        <div className='shadowM w-full bg-[#E8FAEE] rounded-lg md:rounded-[32px] my-10 py-[32px] px-4 md:px-[60px] lg:px-[80px] flex justify-between items-center '>
            <div>
                <h1 className=' font-[800] text-[24px] md:text-[44px] lg:text-[46px] text-[#35393F] tracking-[-4%] leading-[36px]  md:leading-[50px] font-rob'>Build a More Sustainable & <br /><span className='italic font-playfair text-[32px] md:text-[52px] lg:text-[52px] font-[800]'>Profitable Future</span></h1>
                {/* <p className='text-[#444951] font-[600] text-[20px] md:text-[36px] text-center font-Rob '>Earn and save the environment</p> */}
                <p className=' text-[#0F172A] text-[14px] font-[400] lg:w-[80%] py-6 font-Rob'>Join Africa’s fastest-growing waste banking platform and connect with more waste generators while increasing efficiency and revenue</p>
                <div className='flex space-x-10'>
                    <Link to='/be-your-own-boss' className='text-white hover:font-[600] font-[400] px-8 py-2 mr-2 rounded-[3rem] bg-primary border-1 border-primary'>Be Your Own Boss</Link>
                    </div>                
            </div>
            <div className='hidden lg:block'>
                <img src="/img/partnerImg.png" className='h-[300px]' alt="" />
            </div>

        </div>     
    </Container>
  )}
export const Elevate =()=>{
  return (
    <Container>
        <div className='shadowSM w-full bg-[#E8FAEE] rounded-lg md:rounded-[32px] my-10 py-[32px] px-4 md:px-[60px] lg:px-[80px] flex justify-center items-center flex-col '>
                <h1 className=' font-[600] text-center text-[24px] md:text-[44px] lg:text-[46px] text-primary tracking-[-4%] leading-[36px]  md:leading-[50px] font-rob'>Ready to elevate your platform?</h1>
                {/* <p className='text-[#444951] font-[600] text-[20px] md:text-[36px] text-center font-Rob '>Earn and save the environment</p> */}
                <p style={{color:'rgba(1, 29, 1, 0.7)'}} className=' text-center text-[18px] font-[500] lg:w-[55%] py-6 font-Rob'>Fast-track your development today! Integrate our APIs to streamline your building process and achieve your goals sooner.</p>
                <div className='flex'>
                    <Link to='/contact-us' className='text-white hover:font-[600] font-[400] px-8 py-2 mr-2 rounded-[3rem] bg-primary border-1 border-primary'>Contact Us to Get Started</Link>
                    </div>                

        </div>     
    </Container>
  )}
