import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import "swiper/css/pagination";
import { Container } from '../common/AltCard';
// import { Autoplay, Pagination } from 'swiper/modules';
import { Autoplay, Pagination } from "swiper";
import { testimonialCard } from '../../utils/data/data';

const Testimonials3 = () => {
  return (
    <section className='bg-secondary py-6 lg:py-20'>
      <Container>
      <h4 style={{letterSpacing:'0.5px'}} className='text-[#35393F] text-center font-int font-bold mb-6  text-2xl md:text-5xl'>Hear Our <span className='font-playfair italic text-[32px] md:text-[50px] lg:text-[4rem] font-[800] text-primary'>Feedbacks</span></h4> 
          <div className='flex mx-[-15px] pb-[-15px] flex-wrap justify-center items-center flex-row content-stretch'>

              <div className=' lg:pl-[30px]  mb-[15px] flex-1  lg:basis-[50%] w-full lg:w-[50%]'>
                  {/* the swiper */}


              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 4500,
                  disableOnInteraction: true,
                }}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  320: { slidesPerView: 1 }, // Mobile devices
                  640: { slidesPerView: 2 }, // Small tablets
                  1024: { slidesPerView: 3 }, // Laptops and larger tablets
                  1280: { slidesPerView: 3 }, // Desktops
                }}
                navigation={true}
                modules={[Autoplay, Pagination]}
                className="mySwiper"
              >
                  <SwiperSlide>
                  <div className='shadow-lg rounded-lg bg-white h-[200px] p-4'>
                    <div className='mb-6 flex flex-col justify-between h-full'>
                      <p>I requested a pick-up and the response was swift.</p>
                      <div className='flex items-center '>
                          <img src="" alt="" />
                          <div className='self-start'>
                            <h5 className='font-bold text-[#263238] text-lg'>Hodok Orok</h5 >
                            <h6 className='text-xs text-[#35393F] font-thin italic'>@Household User</h6>
                          </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                  <SwiperSlide>
                  <div className='shadow-lg rounded-lg bg-white h-[200px] p-4'>
                    <div className='mb-6 flex flex-col justify-between h-full'>
                      <p>Once you schedule they will always find you to pickup. Very fast, efficient and reliable. I recommend for every household.</p>
                      <div className='flex items-center '>
                          <img src="" alt="" />
                          <div className='self-start'>
                            <h5 className='font-bold text-[#263238] text-lg'>Michael Ogunleye</h5 >
                            <h6 className='text-xs text-[#35393F] font-thin italic'>@Household User</h6>
                          </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                  <SwiperSlide>
                  <div className='shadow-lg rounded-lg bg-white h-[200px] p-4'>
                    <div className='mb-6 flex flex-col justify-between h-full'>
                      <p>This is the best sustainable app I’ve ever seen. Quality services. Keep up the good work.</p>
                      <div className='flex items-center '>
                          <img src="" alt="" />
                          <div className='self-start'>
                            <h5 className='font-bold text-[#263238] text-lg'>Jadesola Samuel</h5 >
                            <h6 className='text-xs text-[#35393F] font-thin italic'>@Household User</h6>
                          </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                

              </Swiper>

              </div>


          </div>

      </Container>


      
    </section>
  )
}

export default Testimonials3

 const TestimonyCard = ()=>{
  return(
    <div className='shadow-lg rounded-lg bg-white h-[200px] p-4'>
      <div className='mb-6'>
        <p>This is the best sustainable app I’ve ever seen. Quality services. Keep up the good work.</p>
        <div className='flex items-center '>
            <img src="" alt="" />
            <div className='self-start'>
              <h5 className='text-[#35393F] font-bold text-[#263238] text-lg'>Jadesola Samuel</h5 >
              <h6 className='text-xs text-[#35393F] font-thin italic'>Household User</h6>
            </div>
        </div>
      </div>
    </div>
  )
 }
