import React, { useEffect, useState } from 'react'
import { Container } from '../common/AltCard'
import * as fbq from '../../lib/fbpixel';
import axios from 'axios';
import CommentTab from '../common/CommentTab';
import '../header/header.css'

const NewHero = () => {
  const slideText = [<HeroText1/>, <HeroText2/>, <HeroText4/>]
  const stagingEnpoint = 'https://stagingapi.pakam.ng/api/facebook/notify'

  const handleDownloadAndroid= async() => {
    fbq.event('Download', { device: 'Andriod' })
    
    try {
      const res = await axios.post(stagingEnpoint, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const handleDownloadIOS= async() => {
    fbq.event('Download', { device: 'IOS' })
    
    try {
      const res = await axios.post(stagingEnpoint, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } catch (error) {
      console.log(error)
    }
  }
  
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % slideText.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);


  return (
    <div className='bg-[#0D1B1A] relative'>
      <img className='w-screen h-full absolute inset-0' src="/img/mesh.png" alt="" />
      <Container>
           {/*NEW HERE SECTION  */}

           <div className='w-full h-[100vh] pt-[4rem] lg:pt-[7rem] md:h-[80vh] lg:h-[100vh] flex flex-col justify-center items-center gap-12 md:gap-8 relative'>             
            <div className="text-slider">
              <div key={index} className="fade-slide">
                {slideText[index]}
              </div>
            </div>

              <div className='flex space-x-5 md:space-x-10  md:mt-10 ' data-aos="flip-up" data-aos-duration="1500" data-aos-easing="ease-in">
                    <a  href="https://apps.apple.com/ng/app/pakam-household-recycling-app/id1539296957">
                      <button onClick={handleDownloadIOS}>
                        <img className='md:h-[50px] md:w-[180px] h-[40px] w-[130px]' src="/img/apple.png" alt="" />
                      </button></a>
                     <a  href="https://play.google.com/store/apps/details?id=com.pakamcustomer">
                      <button onClick={handleDownloadAndroid}>
                        <img className='md:h-[50px] md:w-[180px] h-[40px] w-[130px]' src="/img/Google play.png" alt="" />
                      </button></a>


                </div>
           </div>
      </Container>
    </div>


  )
}

export default NewHero


const HeroText1 = ()=>{
  return(    
  <>
  <h1 className='text-white font-int font-[700] text-[24px] md:text-[48px] lg:text-[60px] text-center leading-[30px] md:leading-[48px] lg:leading-[60px] tracking-[-4%]'>Africa’s Leading AI-Powered<br /><span className='font-[700] font-playfair italic'>Waste Bank</span></h1>
  <p className='text-[14px] opacity-80 md:text-[20px] text-center font-[200] w-full text-white md:w-full'>Turn waste into wealth effortlessly</p>
  </>
  )}            
const HeroText2 = ()=>{
  return(    
  <>
  <h1 className='text-white font-int font-[700] text-[24px] md:text-[48px] lg:text-[60px] text-center leading-[30px] md:leading-[48px] lg:leading-[60px] tracking-[-4%]'>Track Your Environmental<br /><span className='font-[700] font-playfair italic'>Impact</span></h1>
  <p className='text-[14px] opacity-80 md:text-[20px] text-center font-[200] w-full text-white md:w-full'>See how much waste you've recycled and your carbon footprint reduction.</p>
  </>
  )}            
// const HeroText3 = ()=>{
//   return(    
//   <>
//   <h1 className='text-white font-int font-[700] text-[24px] md:text-[48px] lg:text-[60px] text-center leading-[30px] md:leading-[48px] lg:leading-[60px] tracking-[-4%]'>Earn. <span className='font-[700] font-playfair italic'>Save. </span>Invest.</h1>
//   <p className='text-[14px] opacity-80 md:text-[20px] text-center font-[200] w-full text-white md:w-full'>Monitize your recyclables and grow your income</p>
//   </>
//   )}            
const HeroText4 = ()=>{
  return(    
  <>
  <h1 className='text-white font-int font-[700] text-[24px] md:text-[48px] lg:text-[60px] text-center leading-[30px] md:leading-[48px] lg:leading-[60px] tracking-[-4%]'><span className='font-[700] font-playfair italic'>Trade </span>Your Impact as Carbon<br />Credit.</h1>
  <p className='text-[14px] opacity-80 md:text-[20px] text-center font-[200] w-full text-white md:w-full'>Monitize your recyclables and grow your income</p>
  </>
  )}            
