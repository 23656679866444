import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper";

const MarqueeComp = ({title, data}:{title:string, data:string[]}) => {
  return (
    <div className=' w-full mt-6 py-4 md:py-8'>
        <h1 className='font-[600] text-center text-[14px] md:text-2xl text-[#35393F] font-int mb-8'>{title.toUpperCase()}</h1>
        <Swiper
                spaceBetween={10}
                // centeredSlides={true}
                
                loop={true}
                loopedSlidesLimit={false}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: true,
                }}
                breakpoints={{
                  375: {
                      slidesPerView: 1,
                  },
                  425: {
                      slidesPerView: 2,
                  },
                  768: {
                      slidesPerView: 5,
                  },
                  1024: {
                      slidesPerView: 6,
                  },
              }}
                // pagination={{
                //   clickable: true,
                // }}
                // navigation={true}
                modules={[Autoplay, Pagination]}
                className="mySwiper"
              >

                {data.map((el, i) => (
                  <SwiperSlide key={i}>
                            <div className='flex justify-center items-center px-4 py-1 gap-4'>
                                    <img
                                        src={el}
                                        className={``}
                                    />                    
                                </div>
                  </SwiperSlide>
                            ))
                  }



                

              </Swiper>
      
    </div>
  )
}

export default MarqueeComp
