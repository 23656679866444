import React from 'react'

const TermsGenerator = () => {
  return (
    <div className="bg-secondary">
      {" "}
      <div className='pt-10 pl-10px pr-0'>
        <div>
          <p>
          Welcome to Pakam.ng, a waste collection platform connecting waste generators with waste collectors whereby the waste generators schedule their wastes and earn rewards (the “Service”).
          </p>
          <p>
          Pakam.ng is a product of Pakam Technology Limited (“we”, “us”, “Pakam”) which can be accessed through its website, mobile or other applications (“Platform”). 
          </p>
          <p>
          By using this Platform, you (“User”, “You”, “Your”) agree to be legally bound by these terms. If you do not agree, you must cease use of the Platform immediately.
          </p>
        </div>

        <div>
          <h4 className='text-primary text-lg font-medium mb-2 mt-5'>1. General Terms</h4>
          <p>
          1.1. By using our Platform in any manner, you confirm that you accept these terms of use and that you agree to comply with and be bound by them.
          </p>
          <p>
          1.2. You understand that a breach of any of these terms of use may result in your rights to use the services on the Platform being restricted, suspended, or withdrawn.
          </p>
          <p>
          1.3. By using this Platform, you represent and warrant that you are 18 years old and above and are otherwise capable of entering into binding contracts including this Terms of Use.
          </p>
          <p>
          1.4. We may amend these terms of use at any time by amending this page. Please check this page from time to time to take notice of any changes we make as they will be binding on you. If you are an existing User, we will duly inform you of any amendments that are made, by sending a prompt on the Platform.
          </p>
        </div>

        <div>
          <h4 className='text-primary text-lg font-medium mb-2 mt-5'>2. Account and Registration</h4>
          <p>
          2.1. To access the features of this Platform, you will be required to register for an account where you will be required to provide us with some information such as your name, e-mail address, physical address, phone number, or other personal information. Some of this information may be of a confidential nature and may include personally identifiable information (“PII”, “Your Information”).
          </p>
          <p>
          2.2. If you provide Your Information to us then you agree to provide true, current, complete, and accurate information, and not to misrepresent your identity.  You also agree to keep Your Information current and to update Your Information if any of it changes.
          </p>
          <p>
          2.3. Our collection, use, and disclosure of Your Information are governed by this Terms of Use, our Privacy Policy, and any applicable law.
          </p>
          <p>
          2.4. You can decide at any time to terminate your account, in which case your details will be deleted from the Platform.
          </p>
        </div>

        <div>
          <h4 className='text-primary text-lg font-medium mb-2 mt-5'>3. Screening Users or Collectors</h4>
          <p>
          Although we retain the right to do so, Pakam does not screen Users or Collectors or verify information communicated through the Platform. Pakam also retains the right to monitor all communication and information transmitted using our Platform. Pakam may, at its sole discretion, take steps to verify your identity at any time. You agree and authorize that we may use and disclose information, including PII, about you for such purposes, including making inquiries of third parties concerning your identity. You further authorize such third parties to disclose to us such information, as we may request for such purposes, and you agree to hold them and us harmless from any claim or liability arising from the request for or disclosure of such information. Unless otherwise agreed under any other agreement signed by the parties, you agree that we may terminate your access to the Service or Platform at any time, without liability to us, if we are unable at any time, to determine or verify your identity. You may contact us at <a href="support.pakam.ng">support@pakam.ng</a> to notify us of inappropriate or illegal conduct or content you encounter on the Platform.
          </p>
        </div>

        <div>
          <h4 className='text-primary text-lg font-medium mb-2 mt-5'>4. Account Management</h4>
          <p>
          If you have been issued an account by Pakam in connection with your use of the Pakam Platform, you are solely responsible for the safeguarding, security, and confidentiality of your username, password, and other credentials used to access that account and enjoy the services Pakam provides. You are responsible for all transactions and other activities authorized or performed using your username and password or mobile device, whether authorized or unauthorized by you. Pakam will not be responsible for any losses arising from the loss or theft of your information or your mobile device or from unauthorized or fraudulent transactions associated with your Pakam Wallet or your Bank Account. If you suspect or become aware of any unauthorized activity or access to your username, password, or mobile device, you must contact us immediately at <a href="support.pakam.ng">support@pakam.ng</a>.
          </p>
        </div>

        <div>
          <h4 className='text-primary text-lg font-medium mb-2 mt-5'>5. Services Offered to Users</h4>
          <p>
          Pakam offers the following services to Users:
            <br />
          •Waste Pickup Scheduling: Book and manage waste collection from your home. <br />
          •Recycling Rewards: Earn digital rewards for segregating and recycling your wastes under our Earn as You waste program.
          <br />
          •Environmental Impact Tracking: View reports on how your household’s waste management efforts contribute to environmental sustainability and impact.
          </p>
         
        </div>

        <div>
          <h4 className='text-primary text-lg font-medium mb-2 mt-5'>6. Using the Pakam Platform for Waste Scheduling	</h4>
          <p>
          6.1. The Platform allows Users to request a waste pickup and schedule the pickup with any of the listed waste collectors/recycling companies (“Collectors”) nearest or closest to their location on the platform (“Services”). {" "}
          </p>
          <p>
          6.2. You agree that when you schedule your waste for pickup, you are entering into a contract with the Collector. Pakam is not and will not be responsible in any way for the fulfilment of the contract entered into between You and the Collectors via the Platform. As such, disputes arising between You and the Collector from the provision of services by the Collectors will be resolved between the You and the Collector.{" "}
          </p>
          <p>
          6.3. Pakam is not affiliated with or has any form of partnership with the listed Collectors. We are only responsible for connecting you to these Collectors.{" "}
          </p>
          <p>
          6.4. We have no control over and do not guarantee the quality of services provided by the Collectors on the Platform. As such, this and any other such warranties (whether express or implied) are disclaimed by us to the fullest extent permitted by law. Pakam will however, hold the Collectors to the highest operational standards possible. 
          </p>
        </div>

        <div>
          <h4 className='text-primary text-lg font-medium mb-2 mt-5'>7. Scheduling and Cancelling Collection services</h4>
          <p>
          7.1. If you schedule for a collection service and the Collector accepts the schedule pickup, then the collection service is considered to have been ordered. 
          </p>
          <p>
          7.2. When a Collector arrives your stated pickup location on the scheduled date and you do not submit your waste as specified on the Pakam Platform, the request will be deemed cancelled by you. Sometimes a Collector may decide to cancel your scheduled request, please note that Pakam is not responsible for such situations. 
          </p>
          <p>
          7.2. When a Collector arrives your stated pickup location on the scheduled date and you do not submit your waste as specified on the Pakam Platform, the request will be deemed cancelled by you. Sometimes a Collector may decide to cancel your scheduled request, please note that Pakam is not responsible for such situations. 
          </p>
          <p>
          7.3. If you decide to enter any transactions with Collectors outside the Pakam Platform, note that Pakam will not be held responsible in any way for the outcome of such transactions.
          </p>
          <p>
          7.4. If you cancel a collection service request on multiple occasions, we may temporarily block your account as a warning. After multiple such warnings, we may suspend your account. After the suspension period you can ask Pakam to reactivate your account and your application will be reviewed by Pakam. 
          </p>
          <p>
          7.5. Upon the arrival of the Collector, you or your agent will be expected to bring out your sorted waste for collection by the Collector. If your waste is not sorted, and the collector must take on such task you shall pay NGN10 per KG for the task which will be deducted from your total waste weighed. 
          </p>
          <p>
          7.6. You should not allow Collectors entry into your house. Collection transactions should be conducted within your premises. Pakam will not be responsible for any complaints/liabilities that may arise because of your non-compliance with this term.
          </p>
          <p>
          7.7. If you cause any damage to the Collector’s logistics tools (for any reasons), the Collector will have the right to require you to pay for the damage. If you do not pay the damage and/or compensate the damage peaceably, the Collector will be permitted to pursue the claims at its discretion.
          </p>
        </div>

        <div>
          <h4 className='text-primary text-lg font-medium mb-2 mt-5'>9. Providing the Service to You</h4>
          <p>
          9.1. For our compliance purposes and in order to provide the Service to you, you hereby authorize us to, directly or through a third party, obtain, verify, and record information and documentation that helps us verify your identity and Bank Account information. The information you provide us is subject to our Privacy Policy.
          </p>
          <p>
          9.2. When you register for the Service and from time to time thereafter, we may require you to provide and/or confirm information and documentation that will allow us to identify you such as a copy of your government-issued photo ID, a copy of your utility bill, and such other information and documentation that we may require from time to time.
          </p>
          <p>
          9.3. By using the Service and providing your information to us, you automatically authorize us to obtain, directly or indirectly through our third-party service providers and without any time limit or the requirement to pay any fees, information about you and your Bank Account from the financial institution holding your Bank Account and other third-party websites and databases as necessary to provide the Services to you. For purposes of such authorization, you grant Pakam and our third-party service providers a limited power of attorney and appoint Pakam and our third-party service providers as your true and lawful attorney to access third-party websites, servers, and documents; retrieve information; and use your User Information, all as described above, with the full power and authority to do and perform each and every act and thing requisite and necessary to be done in connection with such activities, as fully to all intents and purposes as you might or could do in person.
          </p>
          <p>
          8.4. YOU ACKNOWLEDGE AND AGREE THAT WHEN WE OR OUR THIRD-PARTY SERVICE PROVIDERS ACCESS AND RETRIEVE INFORMATION FROM SUCH THIRD-PARTY WEBSITES, WE AND OUR THIRD-PARTY SERVICE PROVIDERS ARE ACTING AS YOUR AGENT, AND NOT THE AGENT OF OR ON BEHALF OF THE THIRD PARTY. YOU ACKNOWLEDGE AND AGREE THAT WHEN WE OR OUR THIRD-PARTY SERVICE PROVIDERS RECEIVE YOUR BANK ACCOUNT AND OTHER SECURITY INFORMATION IN RELATION TO THE DEBITING OR CREDITING OF YOUR BANK ACCOUNT(S), YOU HAVE AUTOMATICALLY AUTHORISED DEBITS AND CREDITS FROM AND TO YOUR BANK ACCOUNT AS APPLICABLE. 
          </p>
  
        </div>
        <div>
          <h4 className='text-primary text-lg font-medium mb-2 mt-5'>10. Your License, Access and Use of our Platform</h4>
          <p>
          10.1. We own and are the licensor of all rights, titles, and interests, in and to the Platform including all rights under patents, copyrights, design rights, trade secrets, and all other proprietary rights.
          </p>
          <p>
          10.2. Your right to access and use our Platform is personal to you and is not transferable by you to any other person or entity. You are only entitled to access and use our Platform for lawful purposes and pursuant to the terms and conditions of this Terms of Use and our Privacy Policy. Any action by you that, <br />(i) violates the terms and conditions of this Terms of Use and/or the Privacy Policy; <br />(ii) restricts, inhibits or prevents any access, use or enjoyment of our Platform; or <br />(iii) through the use of our Platform, defames, abuses, harasses, offends or threatens others, shall not be permitted, and may result in your loss of the right to access and use our Platform.
          </p>
          <p>
          10.3. The rights granted to you in these Terms are subject to restrictions. You therefore agree that you shall not and shall not encourage or authorize any third party to directly or indirectly: <br />(i) copy, publish, distribute, license, sublicense, sell, resell, rent, lease, transfer, assign, host, or otherwise commercially exploit the Platform including as a service bureau or outsourcing offering or otherwise access or use the Platform other than as expressly permitted hereunder; <br />(ii) modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Platform; <br />(iii) access the Platform in order to build a similar or competitive Platform; and <br />(iv) except as expressly stated herein, no part of the Platform may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means. Any future release, update, or other addition to functionality of the Platform shall be subject to these Terms.
          </p>
          <p>
          10.4. Furthermore, you agree that you will not use any robot, spider, scraper, deep link or other similar automated data gathering or extraction tools, program, algorithm or methodology to access, acquire, copy or monitor our Platform or any portion of our Platform or for any other purpose, without our prior written permission.  Additionally, you agree that you will not: <br />(i) copy, reproduce, modify, create derivative works from, distribute or publicly display any content (except for your personal information) from our Platform without our prior written permission and the appropriate third party, as applicable; <br />(ii) interfere or attempt to interfere with the proper working of our Platform or any activities conducted on our Platform; <br />(iii) bypass any robot exclusion headers or other measures we may use to prevent or restrict access to our Platform, or <br />(iv) interfere or disrupt the Service or servers or networks connected to the Platform, including by transmitting any worms, viruses, spyware, malware or any other code of a destructive or disruptive nature.
          </p>
         
        </div>
        <div>
          <h4 className='text-primary text-lg font-medium mb-2 mt-5'>11. Unacceptable Use of our Platform</h4>
          <p>
          11.1. You shall not and shall not permit any person using your device or account to: <br />(i) use this Platform in a way that causes or could cause damage or restrict the availability or accessibility of the Platform; <br />(ii) extract, mine, scrap proprietary and personal data from this Platform without our express and written permission; <br />(iii) store, copy, transmit, publish or distribute any computer virus, spyware, Trojan horse, worm, keystroke logger or other malicious software via this Platform; <br />(iv) conduct any fraudulent or illegal activities via the Platform.
          </p>
          <p>
          11.2. You understand and agree that if any fraudulent activity is associated with the operation of your account, we shall have the right to apply restrictions to your account and report it to the appropriate law enforcement agencies.
          </p>
          
        </div>
        <div>
          <h4 className='text-primary text-lg font-medium mb-2 mt-5'>12. User Content Rights and Related Responsibilities</h4>
          <p>
          12.1. “User Content”, means, without limitation, any messages, texts, digital files, images, photos, personal profile, artwork, videos, audio, comments, feedback, suggestions, and documents, or any other content you upload, transmit or otherwise make available to Pakam and its Users via the Pakam Platform. We may, in our sole discretion, permit you to from time to time, to submit, upload, publish or otherwise make available to us, through the Platform any User Content. You represent and warrant that you own or otherwise control the rights to your User Content and that every part thereof, is an original work by you, or you have obtained all rights, licenses, consents and permissions necessary to use those parts at all times. You further agree to indemnify Pakam and its affiliates for all claims arising from or in connection with any claims to any rights in your User Content or any damages arising from your User Content.
          </p>
          <p>
          12.2. By submitting User Content on or through the Platform, you grant Pakam a perpetual, irrevocable, transferable, assignable, worldwide, non-exclusive, royalty-free license (with the right to sublicense through multiple tiers) to access, use, re-use, reproduce, transmit, print, publish, display, exhibit, distribute, redistribute, copy, host, store, cache, archive, index, categorize, comment on, broadcast, stream, download, edit, alter, modify, adapt, translate, create derivative works based upon and publicly perform such User Content without attribution, and the requirement of any permission from or payment to you, or any other person or entity, in any manner including, without limitation, for commercial, publicity, trade, marketing, promotional, or advertising purposes, and in any media now known or hereafter devised, under applicable laws.
          </p>
          <p>
          12.3. For us to provide the Service to you, we require that you grant us certain rights concerning User Content, including the ability to manipulate, process, store and copy User Content to provide our Services. Your acceptance of this ‘Terms of Use’ permits us to do so and grants us any such rights necessary to provide the Service to you.
          </p>
          <p>
          12.4. Pakam expressly disclaims any liability for the loss or damage to any User Content or any losses or damages you incur as a result of the loss or damage of any User Content. It is your responsibility to back up any User Content to prevent its loss.
          </p>
          <p>
          12.5. You are solely responsible for your User Content, including, without limitation, comments and feedback.
          </p>
          <p>
          12.6. Pakam may block, remove or return any User Content at any time for any reason whatsoever, or no reason at all. We are not responsible for the authenticity, accuracy, completeness, appropriateness, or legality of User Content.
          </p>
          <p>
          12.7. User warrants and agrees not to: <br />(i) publish falsehoods or misrepresentations that could cause injury, loss, or damage to Pakam or any third party; <br />(ii) submit material that is unlawful, obscene, lewd, defamatory, libelous, threatening, pornographic, harassing, hateful, racially or ethnically offensive, violent, or encourages conduct that would be considered a criminal offense, give rise to civil liability, violate any law, or is otherwise inappropriate or objectionable; <br />(iii) post advertisements or solicitations of business; <br />(iv) impersonate another person; or <br />(v) submit copyrighted material, protected by trade secret or otherwise subject to third party intellectual property or proprietary rights, including privacy and publicity rights unless you are the owner of such rights or have permission from their rightful owner to post the material and to grant Pakam all of the license rights granted herein.
          </p>
          <p>
          12.8. Notwithstanding the foregoing, Pakam assumes no responsibility for monitoring the Service for inappropriate content or modifying or removing such content from the Platform.
          </p>

        </div>
        <div>
          <h4 className='text-primary text-lg font-medium mb-2 mt-5'>13. Good Practice using the Pakam Platform</h4>
          <p>
          13.1. As Pakam is not a provider or broker of the collection/logistic services, any issues with defects or quality of the collection services will be resolved in accordance with the rules and regulations of the waste collection service provider or the relevant public authority. 
          </p>
          <p>
          13.2. We ask that you join the community corner in the Pakam app to provide feedback. This enables us to offer suggestions to the collectors for improving the quality of their service. 
          </p>
          <p>
          13.3. Pakam will make every effort to ensure that only collectors, who have integrity and have displayed professional ethics are onboarded on the Pakam platform. However, we are in no position to guarantee that every collector on our platform always satisfies the criteria. If you experience objectionable collection service, please notify collector or our customer support.
          </p>
        
        </div>
        <div>
          <h4 className='text-primary text-lg font-medium mb-2 mt-5'>14. Interruption of Service</h4>
          <p>Unless otherwise stated in the applicable Service Level Agreement, your access and use of our Platform may be interrupted for any of several reasons, including, without limitation, the malfunction of equipment, periodic updating, maintenance or repair of our Services, Software, or other actions that we may elect to take. Pakam shall have no liability for any damage or loss caused as a result of such interruptions.
          </p>
          
        </div>
        <div>
          <h4 className='text-primary text-lg font-medium mb-2 mt-5'>15. Third Party Links, Services and Content</h4>
          <p>The Platform may contain features, services and functionalities linking you to, or providing you with access to third party services and content, websites, directories, servers, networks, systems, information, databases, applications, software, programs, courses, services, and the Internet as a whole. Because we have no control over such sites and resources, we are not responsible for the availability of such external sites or resources, and do not endorse and are not responsible or liable for any content, advertising, courses or other materials on or available from such sites or resources. When you visit or use a third party’s website you agree to read and consent to the third party’s Terms of Use and Privacy Policy and you release us from any liability.
          </p>
          
        </div>
        <div>
          <h4 className='text-primary text-lg font-medium mb-2 mt-5'>16.  Anti-Money Laundering</h4>
          <p>16.1. You represent and warrant the following:
            <br />•To the best of your knowledge (having made due and careful enquiries), none of the funds withdrawn from or used for investments on the Pakam platform, shall be derived from or related to any activity that is deemed to be in contravention or breach of applicable law;
            <br />•No contribution or payment by you to us shall cause us to be in violation of anti-money laundering rules or any Applicable Law;
            <br />•You shall notify us immediately any of the above ceases to be true or accurate.
          </p>
          <p>16.2. Where we discover that the representations above are untrue or inaccurate or that our Platform or Services are being used for any suspicious transactions as determined by us, you undertake to indemnify us against any loss, damages or expenses that we may incur as a result of it and further acknowledge that we have an obligation to report your activities to the relevant authorities without recourse to you.
          </p>
          
        </div>
        <div>
          <h4 className='text-primary text-lg font-medium mb-2 mt-5'>17. Limitation of Liability</h4>
          <p>17.1. The Pakam Platform is provided on an "as is" and “as available” basis. Pakam does not represent, warrant, or guarantee that access to Pakam Platform will be uninterrupted or error free. In case of any faults in the software, we will endeavor to correct them as soon as possible, but please keep in mind that the functioning of the platform may be restricted due to occasional technical errors, and we are not able to guarantee that the platform will always function. 
          </p>
          <p>17.2. Pakam, its representatives, directors and employees are not liable for any loss or damage that you may incur because of using Pakam app or relying on, the scheduling experience contracted for through the Pakam app, including but not limited to: 
           <br /> •any direct or indirect property damage or monetary loss. 
           <br /> •loss of profit. 
           <br /> •loss of business, contracts, contacts, goodwill, reputation, and any loss that may arise from interruption of the business. 
           <br /> •loss or inaccuracy of data; and 
            any other type of loss or damage.
          </p>
          <p>17.3. The financial liability of Pakam in connection with breach of the contract will be limited to 5000 Naira. Pakam will not be liable for the actions or inactions of the Collectors and will not be liable for damages that the Collectors may cause to You and vice versa. 
          </p>
          <p>17.4. You agree to fully indemnify and hold Pakam, its affiliate companies, representatives, employees, and directors harmless from any claims or losses (including liabilities, damages, costs and expenses of any nature) that they suffer as a result of your use of the Pakam Platform (including the scheduling you obtain through your use of the Pakam app). 
          </p>
          <p>17.5. Pakam may immediately end your use of the Pakam app if you breach these General Terms and Conditions or we consider it necessary to protect the integrity of Pakam app or the safety of generators and collectors.
          </p>
          
        </div>
        <div>
          <h4 className='text-primary text-lg font-medium mb-2 mt-5'>18. Termination</h4>
          <p>18.1. As a user of Pakam App, you may cancel or terminate your account, associated email address and/or access to our services by submitting a cancellation or termination request to <a href="support@pakam.ng">support@pakam.ng</a>.
          </p>
          <p>18.2. You agree that Pakam may, without any prior written notice, immediately suspend, terminate, discontinue and/or limit your account and access to any of our Services. The cause for such termination, discontinuance, suspension and/or limitation of access shall include, but is not limited to:
           <br /> •any breach or violation of our Terms or any other incorporated agreement, regulation and/or guidelines
            <br />•reports from other users as to your objectionable/abusive content.
            <br />•By way of requests from law enforcements or any other governmental agencies.
            <br />•The discontinuance, alteration, and/or material modification to our services, or any part thereof.
            <br />•Unexpected technical or security issues and/or problems.
            <br />•Any extended periods of inactivity.
            <br />•Any engagement by you in fraudulent or illegal activities, and/or.
            <br />•The nonpayment of any associated fees that may be owed by you in association with your Pakam account.
          </p>
          <p>18.3. You also agree that all termination, suspension, discontinuance, or limitation of access, shall be made at our sole discretion and we shall not be liable to you or any third party with regards to the termination of your account and access to our platform.
          </p>
          
        </div>
        <div>
          <h4 className='text-primary text-lg font-medium mb-2 mt-5'>19. Disclaimers; No Warranties</h4>
          <p>19.1. SAVE AS OTHERWISE AGREED IN WRITING BY PARTIES, PAKAM DOES NOT WARRANT THAT THE SERVICES OR THE SERVERS THAT MAKE THE PLATFORM AVAILABLE WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT ANY PRODUCT DESCRIPTION OR OTHER CONTENT OFFERED AS PART OF THE SERVICES ARE ACCURATE, RELIABLE, CURRENT OR COMPLETE.
          </p>
          <p>19.2. YOU EXPRESSLY AGREE THAT YOUR USE OF THE PLATFORM IS AT YOUR SOLE RISK. IF YOU DOWNLOAD ANY CONTENT ON THE PLATFORM, YOU DO SO AT YOUR OWN DISCRETION AND RISK. YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH CONTENT THROUGH THE PLATFORM.
          </p>
          <p>19.3. YOU AGREE TO DEFEND AND HOLD PAKAM AND ANY OF ITS DIRECTORS, AFFILIATES, EMPLOYEES, AGENTS HARMLESS AGAINST ANY AND ALL LEGAL CLAIMS AND DEMANDS, INCLUDING REASONABLE ATTORNEY’S FEES, WHICH MAY ARISE FROM OR RELATE TO YOUR USE OR MISUSE OF THE PLATFORM, YOUR BREACH OF THIS ‘TERMS OF USE OR YOUR ACTIONS. YOU AGREE THAT PAKAM SHALL BE FREE TO ELECT ITS OWN LEGAL COUNSEL AND PARTICIPATE IN ITS OWN DEFENSE IF IT WISHES. 
          </p>
          
        </div>
        <div>
          <h4 className='text-primary text-lg font-medium mb-2 mt-5'>20. Governing Law & Dispute Settlement</h4>
          <p>20.1. You agree that this Terms of Use and any contractual obligation between Pakam and You will be governed by the laws of the Federal Republic of Nigeria.
          </p>
          <p>20.2. If a dispute or difference arises as to the validity, interpretation, effects, or rights and obligations of the Parties under this Terms of Use, the Parties shall use their best endeavor to reach an amicable settlement of the dispute.
          </p>
          <p>20.3. If any such dispute is not settled between the Parties within fourteen (14) days, the Parties agree to submit such dispute to Mediation at the Lagos State Multi-Door Courthouse (LMDC) for resolution under the provisions of Lagos State Multi-Door Courthouse (LMDC) Law 2007.
          </p>

        </div>
        <div>
          <h4 className='text-primary text-lg font-medium mb-2 mt-5'>21. Contact Information</h4>
          <p>If You have any questions/complaints concerning the Pakam Platform, this Terms of Use or anything related to any of the foregoing, Pakam User Support can be reached at the following email address: <a href="mailto:support@pakam.ng">support@pakam.ng</a>
          </p>
          
        </div>
        <br />
        {/* testing...... */}
      </div>
    </div>
  )
}

export default TermsGenerator