import React, { useState } from 'react'
import PrivacyCollector from '../../components/privacy/PrivacyCollector'
import {CareerPageBanner, PageBanner} from '../../components/common/PageBanner'
import { Container } from '../../components/common/AltCard'
import PrivacyGenerator from '../../components/privacy/PrivacyGenerator'
import WhyIntegratePK from '../../components/newHome/WhyIntegratePK'
import ApiForYou from '../../components/newHome/APIForYou'
import PakamAdvantage from '../../components/newHome/PakamAdvatage'
import { Elevate } from '../../components/newHome/Empower'

const OpenAPI = () => {
  return (
    <div className='pt-16'>
    <CareerPageBanner text={'Open API for Sustainability Partnerships'} para="Skip building from scratch—integrate our powerful suite of APIs to enhance your platform with seamless wallet, savings, waste categories, and scheduling features.">
      
    
    </CareerPageBanner> 

    <Container>
      <WhyIntegratePK/>
      <ApiForYou/>
      <PakamAdvantage/>
      <Elevate/>      
    </Container>
    
</div>
  )
}

export default OpenAPI