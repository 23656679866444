"use client"

import {Link, useLocation, useNavigate} from 'react-router-dom';
import React, {useState, useRef, useEffect} from 'react'
import { useAppContext } from '../../utils/Provider';

interface ListObject {
  icon: string;
  link: string;
  title: string;
}
interface NavButtonProp{
    title: string;
    active: boolean;
    link?:string;
    list?: ListObject[];
    Click: () => void;
}
const NavButton = ({list, title, active, link, Click}: NavButtonProp) => {
  const { value, setValue } = useAppContext();
  const [open, setOpen] = useState(false)
  const btnRef = useRef<HTMLDivElement | null>(null)
  const menuRef = useRef<HTMLDivElement | null>(null)
  const navigate = useNavigate()

  const location = useLocation();

  const handleReroute = async (tabname: string) => {
    setValue(tabname); // Update the tab state
    await navigate('/'); // Navigate to the homepage
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (menuRef.current && btnRef.current && e.target instanceof Node) {
        if (!menuRef.current.contains(e.target) && !btnRef.current.contains(e.target)) {
          setOpen(false);
        }
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className='relative p-8 text-center lg:p-0 w-full'>
      {
        link ?
        <Link to={link}>
          <div 
          onClick={()=>setValue('')}
             style={{color: 'white', borderColor:'rgba(0, 0, 0, 1)'}} 
            className={` ${active ?  'border-b' : 'border-none'} text-[#35393F] lg:text-white pb-1 font-[400] text-[16px]`}
          >
            {title} 
          </div>
        </Link>:
              <div 
              onClick={()=>setOpen(!open)} 
              ref={btnRef} style={{color: 'white', borderColor:'rgba(0, 0, 0, 1)'}} 
              className={`${title === 'Become a Partner' && '!text-[#005700] font-[800]'} ${active ?  'border-b' : 'border-none'} flex items-center gap-1 text-[#35393F] lg:text-white pb-1 font-[400] text-[16px]`}
            >
              {title} {title==='Collaborators' && <img className='inline' src='/img/chev-down.png' alt=''/>}
            </div>
      }


      <>
          {
            title === 'Collaborators'?
            <div ref={menuRef} className={`absolute left-5 top-25 lg:top-10 flex-col sm:flex-row justify-start lg:justify-between flex-wrap lg:items-center gap-6 bg-[#fff] w-[180px] px-5 py-6 rounded-lg transition-all duration-500 ease-in-out shadow-lg ${open ? 'flex z-[9999999]':'hidden z-[-1]'} `}>

            {
                list?.map((item, index)=>(
                  <div className='min-w-[45%] w-full hover:font-[700] transition duration-300 ease-in-out'>
                    {/* <Link key={index} to={item.link} > */}
                      <div  
                        onClick={()=>{
                          Click()
                          setOpen(!open)
                          handleReroute(item.link)
                          
                        }}  
                        className={`${item.link === value && 'bg-[#E7FDE7]'} w-full flex justify-between px-1 rounded-md items-center space-x-1`}
                      >
                        <p className={`${item.link === value && 'text-primary'} w-full text-left text-[14px] font-[600]`}>{item.title}</p>
                        {item.link === value && <img src="/img/tick.png" alt=""/>}
                      </div>
                    {/* </Link>                   */}
                  </div>
  
                )
                    
                )
            }
  
  
  
          </div>  
            :
            <div ref={menuRef} className={`absolute left-5 top-25 lg:top-10 flex-col sm:flex-row justify-start lg:justify-between flex-wrap lg:items-center gap-6 bg-[#fff] w-[300px] sm:w-[400px] px-5 py-6 rounded-lg transition-all duration-500 ease-in-out shadow-lg ${open ? 'flex z-[9999999]':'hidden z-[-1]'} `}>

            {
                list?.map((item, index)=>(
                  <div className='min-w-[45%] hover:font-[700] transition duration-300 ease-in-out'>
                    <Link key={index} to={item.link} >
                      <div  
                        onClick={()=>{
                          Click()
                          setOpen(!open)
                          
                        }}  
                        className="flex items-center space-x-1"
                      >
                        <img src={item.icon} alt="nav icon" className='w-[40px] h-[40px]'/>
                        <p className='text-left text-[14px] font-[600]'>{item.title}</p>
                      </div>
                    </Link>                  
                  </div>
  
                )
                    
                )
            }
  
  
  
          </div>  
          }
      </>

      


    </div>
  )
}

export default NavButton
