import React, { useEffect, useState } from 'react'
import WasteGenerators from './newhomeSections/WasteGenerators'
import StatBanner from '../../components/home/StatBanner'
import Recycletowork from '../../components/home/Recycletowork'
import WasteCollectors from './newhomeSections/WasteCollectors'
import Partners from './newhomeSections/Partners'
import NewHero from '../../components/home/NewHero'
import Members from '../../components/home/Members'
import MarqueeComp from '../../components/home/Marquee'
import { useLocation } from 'react-router-dom'
import { useAppContext } from '../../utils/Provider'

const activeBtn = 'bg-primary text-white'
const sectionBtn = 'px-4 py-2 rounded-3xl font-[500] text-[14px]'
const inActiveBtn = 'bg-transparent text-black'
const NewHome = () => {
    const { value, setValue } = useAppContext();
    const [section, setSection] = useState('waste_generator')
      const location = useLocation();

      useEffect(() => {
        if (location.pathname === "/" && value !== "") {
          setSection(value)
          setTimeout(() => window.scrollTo(0, 500), 100);
          
        }
      }, [location.pathname, value]); // Runs every time the route changes
    
    
  return (
    <div >

        <NewHero/>
        <div className='w-full flex justify-center py-[3rem] '>
            <div style={{padding:'2px'}} className='flex justify-between items-center p-[2px] gap-4 rounded-3xl border border-primary  bg-[#F8FFF4]'>
                <button onClick={()=>{setSection('waste_generator'); setValue('waste_generator')}} className={`${section === 'waste_generator'? activeBtn : inActiveBtn} ${sectionBtn}`}>Waste Generator</button>
                <button onClick={()=>{setSection('waste_collector');setValue('waste_collector')}} className={`${section === 'waste_collector'? activeBtn : inActiveBtn} ${sectionBtn}`}>Waste Collectors</button>
                <button onClick={()=>{setSection('partners'); setValue('partners')}} className={`${section === 'partners'? activeBtn : inActiveBtn} ${sectionBtn}`}>Partners</button>
            </div>
        </div>

        <div>
            {
                section === 'waste_generator' ? <WasteGenerators/>:
                section === 'waste_collector' ? <WasteCollectors/>:
                section === 'partners' ? <Partners/>:
                null
            }

            <StatBanner/>
            <Recycletowork/>
            <MarqueeComp title={section === 'waste_generator' ? 'Businesses that Trust Us':
                section === 'waste_collector' ? 'Aggregators THAT PARTNER WITH US':
                section === 'partners' ? 'We are supported by':
                ''} 
            data={section === 'waste_generator' ? generatorsLogo:
                section === 'waste_collector' ? collectorsLogo:
                section === 'partners' ? partnersLogo:
                []}/>
      
            
        </div>
    </div>
  )
}

export default NewHome

const generatorsLogo = [
    '/img/Oo1.png',
    '/img/Oo2.png',
    '/img/Oo3.png',
    '/img/Oo4.png',
    '/img/O5.png',
    '/img/O6.png',
    '/img/O8.png',
    '/img/07.png',
    '/img/09.png',
    '/img/O10.png',
] 
const collectorsLogo = [
    '/img/PAT 1.png',
    '/img/PAT 2.png',
    '/img/PAT 3.png',
    '/img/PAT 4.png',
    '/img/PAT 5.png',
    '/img/PAT 6.png',
    '/img/PAT 7.png',
] 
const partnersLogo = [
    '/img/SUP 1.png',
    '/img/SUP 2.png',
    '/img/SUP 3.png',
    '/img/SUP 4.png',
    '/img/SUP 5.png',
    '/img/SUP 6.png',
    '/img/SUP 7.png',
    '/img/SUP 8.png',
    '/img/SUP 9.png',
] 