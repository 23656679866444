import React from 'react'
import { Build } from '../../../components/newHome/Empower'
import Opportunities from '../../../components/newHome/Opportunities'
import Testimonials3 from '../../../components/newHome/Testimonials3'

const Partners = () => {
  return (
    <div>
        <Build/>
        <Opportunities/>
        <Testimonials3/>
    </div>
  )
}

export default Partners