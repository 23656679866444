import { apiforyou, pakamAdvantage, whyIntegratePK, whyPK } from '../../utils/data/data'
import { Link, useNavigate } from 'react-router-dom'

interface serveCardprops{
  title: string;
  desc:string;
  icon: string;
}

const PakamAdvantage = () => {
  const navigate = useNavigate()
  // const serveTilt = useRef<Array<HTMLDivElement>>([]);

  const ServeCard = ({ title, desc, icon}:serveCardprops) =>{
    return(
      <div className='serve-card relative'>
        <div style={{backgroundColor:'white'}} className={`h-[180px] shadowSM md:h-[180px] rounded-[24px] px-4 py-4 flex flex-col justify-normal gap-6 `}>
          <div className='flex w-full justify-between items-center'>
            <h2 className='font-int text-[#333333] font-[700] text-[24px] md:w-[80%] leading-[28px]'>{title}</h2>
            
          </div>
          <p className='text-[#222D33] font-int font-[500] text-[14px] lg:w-[97%]'>{desc}</p>

        </div>
      </div>
    )
  }

  // useEffect(() => {
  //   return VanillaTilt.init(serveTilt.current, {
  //     reverse: true,
  //   });
  // }, []);
  return (

      <div data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-easing="ease-out"
     data-aos-duration="1000" className='w-full  mt-8 bg-white  p-6'>
        <div className='w-full h-full flex justify-center items-center  flex-col'>
           <h4 style={{letterSpacing:'0.5px'}} className='text-primary font-int font-bold text-left text-[28px] md:text-5xl'>The Pakam Advantage</h4>          
           {/* <p className='text-center font-int font-[300] text-[14px] lg:w-[50%] md:text-[18px] text-[#35393F] mb-4 md:my-8'>Accelerate your development with our APIs. Integrate now and build faster.</p> */}
        </div>

        <div className='flex justify-center items-center'>
            <div className=' grid gap-6 grid-col-1 md:grid-cols-2 lg:grid-cols-3 mt-8'>
                {
                  pakamAdvantage.map((item, index)=>
                  <div
                    key={index}               
                    // ref={(ref) => (serveTilt.current[index] = ref!)} 
                    >
         
                        <ServeCard
                          title={item?.title ?? ''}
                          icon={item.imgUrl}
                          desc={item.text ?? ''}

                        />
                  </div>


                  ) 
                }
            </div>          
        </div>


        </div>      
  )
}

export default PakamAdvantage
