import React, { useEffect, useState } from 'react';
import { Container } from '../common/AltCard';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import * as fbq from '../../lib/fbpixel';
import './ref.css'
import { liveEndpoint, url } from '../home/Calculate';


import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { animated } from '@react-spring/web';
import { useSpring } from '@react-spring/core';
import { CircularProgress } from '@mui/material';
import CommercialForm from './CommercialForm';
import IndividualForm from './IndividualForm';

interface FadeProps {
  children: React.ReactElement;
  in?: boolean;
  onClick?: any;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
  ownerState?: any;
}

interface PopModel {
  JobTitle?: string;
  DepartmentMission?: Array<[]>;
  Responsibility: Array<[]>;
  Requirement: Array<[]>;
  Description?: string;
}

interface ModalProps{
    // openModal: ()=>void;
    popupContent?: any;
    open: boolean;
    onClose: ()=>void;
}

interface InputProps {
  label: string;
  type: string;
  name: string;
  psuedo: string;
  value: string;
  onValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPress?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  show?: boolean;
  error?: string;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
    const {
      children,
      in: open,
      onClick,
      onEnter,
      onExited,
      ownerState,
      ...other
    } = props;
    const style = useSpring({
      from: { opacity: 0 },
      to: { opacity: open ? 1 : 0 },
      onStart: () => {
        if (open && onEnter) {
          onEnter(null as any, true);
        }
      },
      onRest: () => {
        if (!open && onExited) {
          onExited(null as any, true);
        }
      },
    });
  
    return (
      <animated.div ref={ref} style={style} {...other}>
        {React.cloneElement(children, { onClick })}
      </animated.div>
    );
  });
  
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: '80%', // Increase the width as needed
    maxHeight: '80vh', // Set a maximum height for vertical scrolling
    overflowY: 'auto', // Enable vertical scrolling if content exceeds maxHeight
    bgcolor: 'background.paper',
    borderRadius: '20px',
    boxShadow: 24,
    p: 4,
  };

const ReferalFom = () => {
    const ait = useParams()

const { id } = useParams<{ id: string }>();
const [showModal, setShowModal] = useState(false)
const [loading, setLoading] = useState(false)
  const [userType, setUserType] = useState({
    children: [],
    slug:'',
    title:'',
    _id:''
  });
  const [acctTypes, setAcctTypes] = useState([]);
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    phoneNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
    termsCondition: false,
  });

  const [errors, setErrors] = useState({
    firstname: '',
    lastname: '',
    phoneNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
    termsCondition: '',
  });

  const stagingEnpoint = 'https://stagingapi.pakam.ng/api/facebook/notify'

  
  const handleDownloadAndroid= async() => {
    fbq.event('Download', { device: 'Andriod' })
    
    try {
      const res = await axios.post(stagingEnpoint, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const handleDownloadIOS= async() => {
    fbq.event('Download', { device: 'IOS' })
    
    try {
      const res = await axios.post(stagingEnpoint, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } catch (error) {
      console.log(error)
    }
  }


  const [showPassword, setShowPassword] = useState(false);
  const [showconfirmPassword, setShowconfirmPassword] = useState(false);

  const Trigger = async () => {
    try {
      const res = await axios.get(`${url}/collector/api/v2/user/account-types`);
      console.log(res.data);
      setUserType(res?.data?.data[0])
      setAcctTypes(res?.data?.data)
    } catch (error) {
      console.log(error);
    }
  };




  useEffect(() => {
    Trigger();
  }, []);



  return (
    <Container>
      <div className="flex gap-12 pt-[7rem] flex-col md:flex-row">
        <div className='relative'>
          <img src="/img/reff-small.png" className='md:hidden w-full' alt="" />
          <img src="/img/reff1.png" className='hidden md:block' alt="" />
          <img className='absolute bottom-[10rem] right-0 w-[80px] hidden md:block' src="/img/subtract.png" alt="" />
        </div>
        <div className="space-y-8">
          <div className='relative'>
            <h1 className="font-int font-[800] text-primary text-center md:text-left text-[28px] leading-[50px] md:text-[50px]">
              Join the Pakam network
            </h1>
            <p className="font-int font-[500] text-[#35393F] text-center md:text-left text-[14px] md:text-[18px] mt-4 md:w-[85%]">
              Discover the benefits of partnering with Pakam and take your business to the next level
            </p>
            <img className='absolute bottom-[-1.5rem] hidden md:block left-[-1.5rem] w-[25px]' src="/img/subtract.png" alt="" />
          </div>
          <div className="bg-white p-6 shadow-lg rounded-md space-y-8">
              <div>
                <h2 className="font-int font-[700] text-[#27272A] text-[20px]">Get started in minutes</h2>
                <p className="font-int font-[500] text-[#444951] text-[14px]">
                  Simply fill out the form below to begin your onboarding journey and start enjoying these benefits.
                </p>
              </div>

              <div style={{backgroundColor:'rgba(240, 241, 244, 0.3)'}} className="flex flex-col md:flex-row rounded-md gap-6 justify-center items-center w-full px-4 py-2 mx-auto">
                <button
                  type="button"
                  onClick={() => setUserType(acctTypes[0])}
                  // onClick={() => setShowModal(true)}
                  className={`${userType?.slug === 'individual' ? 'bg-primary text-white' : ' border '} flex items-center gap-3 rounded-md px-3 py-2`}
                >
                  <img src={`${userType?.slug === 'individual' ? '/img/iconradio-select.png': '/img/iconradio.png'}`} alt="" />
                  <span className='text-[14px]'>Sign up as an Individual</span>
                </button>
                <button
                  type="button"
                  onClick={() => setUserType(acctTypes[1])}
                  className={`${userType?.slug === 'commercial' ? 'bg-primary text-white' : ' border '} flex items-center gap-3 rounded-md px-3 py-2`}
                >
                  <img src={`${userType?.slug === 'commercial' ? '/img/iconradio-select.png': '/img/iconradio.png'}`} alt="" />
                  <span className='text-[14px]'>Sign up as Commercial</span>
                  
                </button>
              </div>

              {
                userType?.slug === 'commercial'? <CommercialForm new_data={acctTypes[1]}/> : <IndividualForm/>
              }


          </div>

        </div>
      </div>



      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={showModal}
        onClose={()=>setShowModal(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        {/* <Fade in={()=>setShowModal(false)}> */}
          <Box sx={style}>
          <div className=" flex justify-end
                  self-end
                   
                  mb-0
                  bg-Black font-extrabold">
          <button className="cursor-pointer" onClick={()=>setShowModal(false)}
>
            <img src="/images/png/close.png" alt=""  />
          </button>
        </div>
            
        <>
           <div className=' flex flex-col gap-8 justify-center items-center'>
                <img src="/img/mark-icon.png" className='w-[100px]' alt="" />
                <div>
                    <h2 className="font-int text-center font-[700] text-[#27272A] text-[24px]">Your account has been created successfully</h2>
                    <p className="font-int text-center font-[500] text-[#444951] text-[16px]">Download the Pakam app to login to your account</p>
                </div>
                <div className='flex gap-3'>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.pakamcustomer"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <button onClick={handleDownloadAndroid}>
                                <img
                                src="/img/play-black.png"
                                alt="play store"
                                className='w-[120px] h-[40px]'
                                />                                
                            </button>

                        </a>

                        <a
                            href="https://apps.apple.com/ng/app/pakam-household-recycling-app/id1539296957"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <button onClick={handleDownloadIOS}>
                                <img
                                src="/img/ios-black.png"
                                alt="app store"
                                className='w-[120px] h-[40px]'
                                />                                
                            </button>

                        </a>
                        </div>
           </div>
        </>

          </Box>
        {/* </Fade> */}
      </Modal>
    </Container>
  );
};

export default ReferalFom