import React from 'react'
import { Expand } from '../../../components/newHome/Empower'
import RecyclingMgt from '../../../components/newHome/RecyclingMgt'
import FeaturedSnapshot from '../../../components/newHome/FeaturedSnapshot'

const WasteCollectors = () => {
  return (
    <div>
        <Expand/>
        <RecyclingMgt/>
        <FeaturedSnapshot/>
        
    </div>
  )
}

export default WasteCollectors