import React from 'react'
import GreenBackDrop from '../common/Green'
import { Link } from 'react-router-dom'
import { FaWhatsapp } from "react-icons/fa";
import { Container } from '../common/AltCard';
import axios from 'axios';
import * as fbq from '../../lib/fbpixel';

interface FHProps{
    text: string
}

const FooterHeader = ({text}:FHProps)=>{
    return(
        <h1 className='text-lg text-white font-bold'>{text}</h1>
    )
}
const FooterP = ({text}:FHProps)=>{
    return(
        <p className='text-base text-white hover:cursor-pointer'>{text}</p>
    )
}

const Footer = () => {

    const stagingEnpoint = 'https://stagingapi.pakam.ng/api/facebook/notify'
    const currentYear = new Date().getFullYear();
  
    const handleDownloadAndroid= async() => {
      fbq.event('Download', { device: 'Andriod' })
      
      try {
        const res = await axios.post(stagingEnpoint, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
      } catch (error) {
        console.log(error)
      }
    }
  
    const handleDownloadIOS= async() => {
      fbq.event('Download', { device: 'IOS' })
      
      try {
        const res = await axios.post(stagingEnpoint, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
      } catch (error) {
        console.log(error)
      }
    }
  

  return (

        <div className='pt-4 bg-[#003900]'>
            <Container>
                <div className='flex flex-col md:flex-row justify-between'>
                    <div>
                        <div className='space-y-6'>
                                <img className='w-[120.68px] h-[40px]' src="/img/pakam_logo_white.png" alt="" />
                                <FooterP text='Pakam enabling people, planet and profit'/>
                                <div className='flex gap-3'>
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.pakamcustomer"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <button onClick={handleDownloadAndroid}>
                                        <img
                                        src="/img/Google play.png"
                                        alt="play store"
                                        className='w-[120px] h-[40px]'
                                        />                                
                                    </button>

                                </a>

                                <a
                                    href="https://apps.apple.com/ng/app/pakam-household-recycling-app/id1539296957"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <button onClick={handleDownloadIOS}>
                                        <img
                                        src="/img/apple.png"
                                        alt="app store"
                                        className='w-[120px] h-[40px]'
                                        />                                
                                    </button>

                                </a>
                                </div>
                        </div>  

                        <div className='hidden lg:flex py-5'>
                            <p className='text-white font-[400] text-[14px]'>© {currentYear} Pakam. All rights reserved</p>
                        </div>                          
                    </div>
                 
                    <div className='flex flex-col lg:flex-row gap-12'>
                        <div className='grid lg:grid-cols-2 items-start lg:justify-center gap-5 lg:gap-10 py-14'>

                            {
                                footerData.map((item, index)=>{
                                    return(
                                        <div className='space-y-6'>
                                            <FooterHeader text={item.header}/> 
                                            <ul className='space-y-2'>
                                                {
                                                    item.asset.map((i, index)=>{
                                                        return(
                                                            <li key={index}><Link to={i.url}><FooterP text={i.name}/></Link></li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    )
                                })
                            }

                        </div> 
                        <div className='space-y-6 lg:pt-14'>
                            <FooterHeader text={'Contact Us'}/> 

                            <div className='space-y-2'>
                                <FooterP text='374, Ikorodu road, Maryland.'/>
                                <FooterP text='+234 - 912 261 6778'/>
                                <FooterP text='+234 - 708 664 6637'/>
                                <FooterP text='info@pakam.ng'/>

                                <div className='flex gap-3'>
                                    <a
                                    href="https://web.facebook.com/people/Pakam/100067730415458/"
                                    target="_blank"
                                    rel="noreferrer"
                                    >
                                    <img
                                        src="/img/facebook.png"
                                        alt="facebook"
                                        className='w-[25.82px] h-[26.67px]'
                                    />
                                    </a>

                                    <a
                                    href="https://www.instagram.com/pakam_ng/?igshid=YmMyMTA2M2Y%3D"
                                    target="_blank"
                                    rel="noreferrer"
                                    >
                                    <img
                                        src="/img/instagram.png"
                                        alt="instagram"
                                        className='w-[25.82px] h-[26.67px]'
                                    />
                                    </a>

                                    <a
                                    href="https://twitter.com/pakamnigeria?s=21&t=uMsG_wAOUm9bn0s5lDb9YQ"
                                    target="_blank"
                                    rel="noreferrer"
                                    >
                                    <img
                                        src="/img/twitter.png"
                                        alt="twitter"
                                        className='w-[25.82px] h-[26.67px]'
                                    />
                                    </a>

                                    <a
                                    href="https://www.linkedin.com/company/pakam-technology-limited/"
                                    target="_blank"
                                    >
                                    <img
                                        src="/img/pajamas_linkedin.png"
                                        alt="linkedin"
                                        className='w-[25.82px] h-[26.67px]'
                                    />
                                    </a>

                                    <a
                                    href="https://api.whatsapp.com/message/7U3WYHMHI3HPF1?autoload=1&app_absent=0"
                                    target="_blank"
                                    rel="noreferrer"
                                    >
                                    <FaWhatsapp fontSize={27} color="white" />
                                    </a>
                                </div>
                            </div>

                        </div>                       
                    </div>

                </div>
                <div className='flex lg:hidden justify-center items-center border-t-[1.3px] py-5'>
                            <p className='text-white font-[400] text-[14px]'>© {currentYear} Pakam. All rights reserved</p>
                </div>
            </Container>
           
        </div>


  )
}

export default Footer


const footerData = [
    // {
    //     header: 'Partner with Pakam',
    //     asset: [
    //         {
    //             name: 'Sign Up as a Space Owner',
    //             url:"https://forms.zohopublic.com/pakamtechnologylimited/form/PAKAMSPACEOWNERSREGISTRATION/formperma/DGaTy7oPVQS32JY9cKtlFZ12Pm0BcwewygSq9jcW0_4",
    //         },
    //         {
    //             name: 'Sign Up as a Business Owner',
    //             url:"https://forms.zohopublic.com/pakamtechnologylimited/form/REGISTRATIONOFRECYCLINGBUSINESSPARTNERS/formperma/Itltm_rbhvj42EHvTbzAfMgya0sIQafsssEZj0iUeO0",
    //         },
    //         {
    //             name: 'Logistic',
    //             url:'https://forms.zohopublic.com/pakamtechnologylimited/form/WASTEPICKERSREGISTRATION/formperma/590lVk12sJ35PJwvKzV7SXvGS9vce6URWx8qcxcGHwM',
    //         },
    //     ]
    // },
    {
        header: 'Company',
        asset: [
            {
                name: 'About Us',
                url:'/about',
            },
            {
                name: 'Recycling Community',
                url:'/',
            },
            {
                name: 'Collector Community',
                url:'/',
            },
            {
                name: 'Partnering Community',
                url:'/',
            },
            {
                name: 'Join Our Team',
                url:'/about',
            },
            // {
            //     name: 'FAQs',
            //     url:'/faqs',
            // },
            {
                name: 'Reviews',
                url:'/reviews',
            },
            // {
            //     name: 'Career',
            //     url:'/careers',
            // },
        ]
    },
    {
        header: 'Legal',
        asset: [
            {
                name: 'Cookie Policy',
                url:'/cookies',
            },
            {
                name: 'End User License Agreement',
                url:'end-user-policy',
            },
            {
                name: 'Privacy Policy',
                url:'privacy-policy',
            },
            {
                name: 'Terms and Conditions',
                url:'terms-and-conditions',
            },
        ]
    },
    {
        header: 'Resources',
        asset: [
            {
                name: 'Blog',
                url:'/blog',
            },
            {
                name: 'News',
                url:'/news',
            },
            {
                name: 'Publications and podcast',
                url:'/publications',
            },

        ]
    },
]