import { Container } from '@mui/material'
import React from 'react'

const StatBanner = () => {
  return (
    <Container>
        <div data-aos="flip-up" data-aos-anchor-placement="top-center" data-aos-easing="ease-in"
     data-aos-duration="2000" className='flex flex-col md:flex-row justify-between gap-10 md:gap-0 items-center w-full py-[4rem] px-[4rem] lg:px-[8rem] bg-[#005A00] rounded-lg'>
            <div className='flex gap-3 justify-center items-center space-y-1'>
                <img src='/img/stat1.png' alt='icon' className='w-[60px] h-[60px]'/>
                <div>
                    <h1 className='font-Rob text-white font-[600] text-[32px]'>700 Tons</h1>
                    <p className='text-[#F6F9F4] text-[12px] font-Rob font-[400] text-center'>Total materials recycled</p>   
                </div>
                
            </div>
            <div className='flex gap-3 justify-center items-center space-y-1'>
                <img src='/svg/persons.svg' alt='icon' className='w-[60px] h-[60px]'/>
                <div>
                  <h1 className='font-Rob text-white font-[600] text-[32px]'>26k+</h1>
                    <p className='text-[#F6F9F4] text-[12px] font-Rob font-[400] text-center'>Number of users</p>  
                </div>
                
            </div>
            <div className='flex gap-3 justify-center items-center space-y-1'>
                <img src='/img/stat2.png' alt='icon' className='w-[60px] h-[60px]'/>
                <div>
                    <h1 className='font-Rob text-white font-[600] text-[32px]'>₦62M</h1>
                    <p className='text-[#F6F9F4] text-[12px] font-Rob font-[400] text-center'>Total rewards paid</p>                    
                </div>

            </div>
        
        </div>        
    </Container>

  )
}

export default StatBanner