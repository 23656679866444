export const data = [
    {
        title: 'Home',
        link: '/'
    },
    {
        title: 'Resources',
        dropdown: [
            {
                title:'Blog',
                icon:'/svg/blogIcon.svg',
                link:'/blog'
            },
            {
                title:'News',
                icon:'/svg/NewsIcon.svg',
                link:'/news'
            },
            {
                title:'Publication & Podcast',
                icon:'/svg/PubIcon.svg',
                link:'/publications'
            }
        ]
    },
    {
        title: 'Company',
        dropdown: [
            {
                title:'About us',
                icon:'/svg/abtIcon.svg',
                link:'/about'
            },
            {
                title:'Join our Team',
                icon:'/svg/JoinIcon.svg',
                link:'/careers'
            },
            {
                title:'Reviews',
                icon:'/svg/RevIcon.svg',
                link:'/reviews'
            }
        ]
    },
    {
        title: 'Developer',

        dropdown: [
            {
                title:'Our API',
                icon:'/img/api-book.png',
                link:'/open-api'
            },
            // {
            //     title:'Pay as You Waste',
            //     icon:'/svg/PayIcon.svg',
            //     link:'/products/pay'
            // },
            // {
            //     title:'Smart Enforcement',
            //     icon:'/svg/SmartIcon.svg',
            //     link:'/products/smart'
            // }
        ]
    },
    // {
    //     title: 'Products',
    //     dropdown: [
    //         {
    //             title:'Earn as You Waste',
    //             icon:'/svg/EarnIcon.svg',
    //             link:'/products/earn'
    //         },
    //         {
    //             title:'Pay as You Waste',
    //             icon:'/svg/PayIcon.svg',
    //             link:'/products/pay'
    //         },
    //         {
    //             title:'Smart Enforcement',
    //             icon:'/svg/SmartIcon.svg',
    //             link:'/products/smart'
    //         }
    //     ]
    // },

    // {
    //     title: 'Help',
    //     dropdown: [
    //         {
    //             title:'Support',
    //             icon:'/svg/SupIcon.svg',
    //             link:'/support'
    //         },
    //         {
    //             title:'Contact',
    //             icon:'/svg/ContIcon.svg',
    //             link:'/contact-us'
    //         },
    //         {
    //             title:'FAQs',
    //             icon:'/svg/FAQIcon.svg',
    //             link:'/faqs'
    //         }
    //     ]
    // }
]
export const partner = [
    {
        title: 'Collaborators',
        dropdown: [
            {
                title:'Household',
                icon:'/img/SIGN.png',
                link:'waste_generator'
            },
            {
                title:'Organizations',
                icon:'/img/BYB.png',
                link:'waste_collector'
            },
            {
                title:'Partners',
                icon:'/img/BYB.png',
                link:'partners'
            },

        ]
    },

]
// export const partner = [
//     {
//         title: 'Become a Partner',
//         dropdown: [
//             {
//                 title:'Recycling Organization Signup',
//                 icon:'/img/SIGN.png',
//                 link:'/onboarding'
//             },
//             {
//                 title:'Be Your Own Boss',
//                 icon:'/img/BYB.png',
//                 link:'/be-your-own-boss'
//             },

//         ]
//     },

// ]