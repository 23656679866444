import React, { ReactNode } from 'react'
import { Container } from '../../components/common/AltCard';
import { Link, useNavigate } from 'react-router-dom';
import { BsArrowUpRight } from "react-icons/bs";
import { contact, contact2 } from '../../utils/data/data';
import { Map } from '../../components/Map';


interface H2props{
    text:string|undefined
  }

  interface DivProps {
    children: ReactNode;
    className?: string
  }

  export const ConatctTextContainerLink:React.FC<DivProps> = ({children})=>(
    <div className="text-primary font-thin text-sm">
        {children}
    </div>
)
  export const ContactContainer:React.FC<DivProps> = ({children, className})=>(
    <div className={`grid lg:grid-cols-2 gap-3 lg:items-start justify-center ${className}`}>
        {children}
    </div>
)
  export const ConatctTextContainerLinks:React.FC<DivProps> = ({children})=>(
    <div className="flex items-center gap-5">
        {children}
    </div>
)
  export const ConatctTextContainerh6 = ({text}:H2props)=>(
    <h6 className="font-bold text-lg lg:text-xl text-blak-100">
        {text}
    </h6>
)
  export const ConatctTextContainerP = ({text}:H2props)=>(
    <p className="text-blak-100 text-base">
        {text}
    </p>
)

const ContactUs = () => {
  // {
  //   imgSrc: "/img/whatsappGreen.png",
  //   imgLink:
  //     "https://api.whatsapp.com/message/7U3WYHMHI3HPF1?autoload=1&app_absent=0",
  // },
    const router = useNavigate()
    return (
        <section className='bg-secondary md:py-[3.2rem] relative'>


          <div className='py-[20px]'>
            
          <div className=" what-box w-full">
            <div className='box'>
             <a href="<?=$whatsappLink;?>"  className='beeping-container space-x-3' target="_blank">
              <img src="/img/whatsappGreen.png" className='beeping-image ' alt="" />
              <span className='text-primary transition-all duration-500 font-bold'>Chat Us On Whatsapp</span>
            </a>  
            </div>
            
          </div>
          <Container>
            <h1 className='font-extrabold text-[48px] text-blak-100 text-left mb-10'>Contact Us</h1>
            <div className="space-y-12">
              <ContactContainer>
                <div>
                  <img
                    src="/img/contact.png"
                    height={387}
                    width={580}
                    className='object-cover'
                  />
                </div>
                <div className='space-y-12 '>
                {contact2.map((el, i) => {
                    return (
                      <div className='space-y-3' key={i}>
                        <ConatctTextContainerh6 text={el.title}/>
                        <ConatctTextContainerP text={el.text}/>
                        {/* <ConatctTextContainerLinks>
                          {el.links &&
                            el.links!.map((el: string | undefined, i: any) => (
                              <Image
                                src={el!}
                                key={i}
                                alt="socials"
                                width={25.82}
                                height={26.67}
                              />
                            ))}
                        </ConatctTextContainerLinks> */}
                        <ConatctTextContainerLinks>
                          {el.links?.map((el, i) => {
                            return (
                            <Link to={el.imgLink} key={i}>
                                <img
                                    src={el.imgSrc}
                                    key={i}
                                    alt="socials"
                                    width={25.82}
                                    height={26.67}
                                />
                            </Link>
                            );
                          })}
                        </ConatctTextContainerLinks>
                      </div>
                    );
                  })}

                </div>
              </ContactContainer>
    
              <ContactContainer className="!flex !flex-col-reverse lg:!grid lg:!grid-cols-2">
                <div className='space-y-6'>
                {contact.map((el, i) => {
                    return (
                      <div className='pb-8 space-y-3' key={i}>
                        <ConatctTextContainerh6 text={el.title}/>
                        <ConatctTextContainerP text={el.text}/>
                        <ConatctTextContainerLink>
                          {/* <a> {el.link}</a> */}
    
                          <div
                            onClick={() => router(`${el.linkto}`)}
                            className="hover:cursor-pointer w-[30%] flex items-center justify-start gap-1"
                          >
                            {el.link}
                            <span>
                              <BsArrowUpRight />
                            </span>
                          </div>
                        </ConatctTextContainerLink>
                      </div>
                    );
                  })}
                </div>
                {/* <div> */}
                {/* <Image
                    src="/img/contact1.png"
                    height={387}
                    width={580}
                    alt="contact"
                    objectFit="contain"
                  /> */}
                <div>
                  <Map />
                </div>
                
                {/* </div> */}
              </ContactContainer>
            </div>
          </Container>
          </div>

        </section>
      );
}

export default ContactUs
