import React from 'react'
import Effortless from '../../../components/home/Effortless'
import Empower from '../../../components/newHome/Empower'
import Steps from '../../../components/newHome/Steps'
import WhyPK from '../../../components/newHome/WhyPK'
import Testimonials3 from '../../../components/newHome/Testimonials3'

const WasteGenerators = () => {
  return (
    <div>
      <Empower/>
      <Effortless/>
      <WhyPK/>
      <Steps/>
      <Testimonials3/>
    </div>
  )
}

export default WasteGenerators