import React from 'react'
import { Container } from '../common/AltCard'

const FeaturedSnapshot = () => {
  return (
    <Container>
        <div className='w-full  mt-8 flex justify-center items-center flex-col my-10 space-y-10'>
            <h4 style={{letterSpacing:'0.5px'}} className='text-center text-[#35393F] font-Rob font-[600] text-[28px] leading-[28px] md:text-[44px] md:leading-[45px]'>Featured <span className='text-[#005A00] text-[32px] md:text-[50px] lg:text-[55px] font-[800] font-playfair italic'>Snapshots</span></h4> 
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8'>
                <img src="/img/f1.png" alt="" />
                <img src="/img/f2.png" alt="" />
                <img src="/img/f3.png" alt="" />
            </div>
        </div>        
    </Container>

  )
}

export default FeaturedSnapshot