import React from 'react'
import { Container } from '../common/AltCard'

const Steps = () => {
  return (
    <Container>
        <div className='w-full space-y-6 py-[3rem]'>
            <div className='flex justify-end'>
                <span className='lg:w-[40%] text-[#7A828F] text-[14px]'>Join a network of responsible waste generators and recycling organizations with these few steps.
                </span>
            </div>
            <div className='flex justify-center items-center flex-col md:flex-row gap-12 w-full'>
                <img className='h-[50vh] md:h-[80vh]' src="/img/step1.png" alt="" />
                <img className='h-[50vh] md:h-[80vh]' src="/img/step2.png" alt="" />
            </div>
        </div>

    </Container>
  )
}

export default Steps