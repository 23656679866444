import React, { createContext, useContext, useState, ReactNode } from "react";

// Define the context type
interface AppContextType {
  value: string;
  setValue: (newValue: string) => void;
}

// Create the context with a default value
const AppContext = createContext<AppContextType | undefined>(undefined);

// Create a provider component
export const ValueProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [value, setValue] = useState("");

  return (
    <AppContext.Provider value={{ value, setValue }}>
      {children}
    </AppContext.Provider>
  );
};

// Custom hook to use the context
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};
