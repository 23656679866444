
import { recyclingMGT, whyPK } from '../../utils/data/data'
import { Link, useNavigate } from 'react-router-dom'

interface serveCardprops{
  title: string;
  desc:string;
  icon: string;
}

const RecyclingMgt = () => {
  const navigate = useNavigate()
  // const serveTilt = useRef<Array<HTMLDivElement>>([]);

  const ServeCard = ({ title, desc, icon}:serveCardprops) =>{
    return(
      
      <div className='serve-card '>
        
        <div style={{backgroundColor:'white'}} className={`shadowM h-[250px] lg:h-[350px] md:h-[250px] rounded-[24px] px-4 py-4 flex flex-col justify-between md:justify-normal gap-4 `}>
          <img src={icon} className='w-[60px] h-[60px]' alt="icon" />
          <div className='flex w-full justify-between items-center'>
            <h2 className='font-int text-[#333333] font-[700] text-[16px] w-[80%] leading-[28px]'>{title}</h2>
            
          </div>
          <p className='text-[#222D33] font-int font-[500] text-[14px] lg:w-[97%]'>{desc}</p>

        </div>
          
      </div>
    )
  }

  // useEffect(() => {
  //   return VanillaTilt.init(serveTilt.current, {
  //     reverse: true,
  //   });
  // }, []);
  return (

      <div data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-easing="ease-out"
     data-aos-duration="1000" className='w-full  mt-8 bg-[#E8FAEE]  p-6'>
        <div className='w-full h-full flex justify-center items-center flex-col'>
            <h1 className='text-center text-[#35393F] font-Rob font-[600] text-[28px] leading-[28px] md:text-[44px] md:leading-[45px]'>Effortless <span className='text-[#005A00] text-[32px] md:text-[50px] lg:text-[55px] font-[800] font-playfair italic'>Recycling</span> Management</h1>
            <p className='text-center font-int font-[500] text-[16px] text-[#7A828F] my-4 md:my-8'>Schedule pickups, earn rewards, and connect with others—all in one place.</p>
        </div>
 
        <div className='flex justify-center items-center'>
            <div className='lg:w-[80%] grid gap-6 grid-col-1 md:grid-cols-2 lg:grid-cols-3 mt-8'>
                {
                  recyclingMGT.map((item, index)=>
                  <div
                    key={index}               
                    // ref={(ref) => (serveTilt.current[index] = ref!)} 
                    >
                        <ServeCard
                          title={item?.title ?? ''}
                          icon={item.imgUrl}
                          desc={item.text ?? ''}

                        />
                  </div>


                  ) 
                }
            </div>          
        </div>


        </div>      
  )
}

export default RecyclingMgt
